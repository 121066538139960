<template>
    <item-list-view
        :api="api"
        :headers="headers"
        id-key="id"
        title="Страницы"
        :allowed-search="false"
        :allowed-create="true"
        :allowed-remove="true"
        update-route="Page"
        :create-route="{ name: 'Page', params: { id: 'new' }}"
        remove-title="Удаление страницу"
        remove-message="Вы действительно хотите удалить страницу '<%= item.title %>'?"
    >
        <template v-slot:item.title="{ item }">
          <div class="d-flex" :class="!item.enabled ? 'text--disabled' : ''">
            <v-icon small title="Status" class="mr-2" :color="item.enabled ? 'green' : 'grey'">{{ item.enabled ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}</v-icon>
            <v-icon small title="In menu" class="mr-2" v-if="item.inMenu">mdi-format-list-bulleted</v-icon>
            <span>{{ item.title }}</span>
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateTime }}
        </template>
    </item-list-view>
</template>

<script>
import api from '../../services/pages'
import ItemListView from '../../components/ItemListView'

export default {
  components: { ItemListView },
  data: () => ({
    api: api,
    headers: [
      { text: 'Заголовок', value: 'title' },
      { text: 'Код', value: 'name' },
      // { text: 'Статус', value: 'enabled' },
      { text: 'Url', value: 'uri' },
      // { text: 'Приоритет', value: 'priority' },
      { text: '', value: 'action' }
    ]
  })
}
</script>
